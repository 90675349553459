import React from 'react';

const Benefits = ({ benefit }) => {
  if (benefit) {
    const message = (
      <span>
        {benefit.length === 1
          ? this.t('parking:payments:privilegeApplied')
          : this.t('parking:payments:privilegesApplied')}
        &nbsp;
      </span>
    );

    const benefits = Array.isArray(benefit)
      ? benefit.join(',')
      : benefit;

    return (
      <Fragment>
        {message}
        {benefits}
      </Fragment>
    );
  }

  return null;
};

const Cost = ({ costData }) => {
  if (costData) {
    const discount = String(costData.sumSale).split('.');
    return (
      <>
        <div className="calculate-result text-secondary">
          <span>Цена за парковку </span>
          <span className="sum neutral">
            <span>{costData.price}</span>
            <span className="rest">.{costData.priceRest}</span>
          </span>

          <span>. </span>
          {costData.sumSale ? (
            <span>
              <span>
                <Benefits />
                <span>
                  (Скидка &nbsp;
                  <span className="sum neutral">
                    {discount[0]}.<span className="rest">{discount[1]}</span>
                  </span>
                  )
                </span>
              </span>
              <span>. </span>
            </span>
          ) : null}
        </div>
      </>
    );
  }
  return null;
};

export default Cost;
